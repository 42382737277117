.ready-made_kits{
    width: 100%;
    padding: 60px 7%;
}
.ready-made_kits h3{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
    text-decoration: none;
}
.ready-made_all{
    width: 100%;
    padding-top: 40px;
}
.ready-made_kits_box{
    overflow: hidden;
    position: relative;
    gap: 30px;
}
.ready-made_kits_catalog{
    width: 100%;
    padding: 20px;
    display: grid;
    grid-template-rows: 300px 100px;
    gap: 20px;
    background-color: white;
    cursor: pointer;
    border-radius: 8px;
}
.ready-made_kits_catalog .img{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ready-made_kits_catalog img{
    max-width: 80%;
    max-height: 200px;
    width: auto;
    height: auto;
    padding-bottom: 10px;
}
.ready-made_kits_content{
    max-height: 130px;
    overflow:scroll;
    scrollbar-width: none;
    font-weight: 300;
    font-size: 14px;
    text-decoration: none;
}
.ready-made_kits_content h5{
    font-size: 20px;
    font-weight: 300;   
    text-decoration: none;
}
.ready-made_kits_price{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ready-made_kits_price p{
    font-size: 24px;
    font-weight: 300;   
    padding-left: 10px;
}
.ready-made_kits_price .bascet{
    width: 120px;
    height: 45px;
    border-radius: 8px;
    border: none;
    background-color: rgb(10, 107, 182);
    color: white;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ready-made_kits_catalog:hover{
    border: 1px solid rgb(10, 107, 182);
    box-shadow: 0 0 9px rgba(36, 93, 136, 0.5);
}
.swiper-button-prev:after, .swiper-button-next:after{
    border-radius: 50%;
    background-color: #11203847;
    padding: 15px;
    font-size: 36px;
    font-weight: 600;
    color: #112038;
}
.swiper-button-prev:hover, .swiper-button-next:hover{
    transform: scale(1.1)
}
@media (max-width:1260px){
    .ready-made_kits{
        width: 100%;
        padding: 60px 5%;
    }
}
@media (max-width:1100px) {
    .ready-made_kits{
        width: 100%;
        padding: 20px 5%;
    }
    .ready-made_kits h3{
        font-size: 40px;
        font-weight: 300;
    }
    .ready-made_all{
        width: 100%;
    }
    .ready-made_kits_box{
        padding-top: 20px;
        gap: 20px;
        columns: 1;
    }
    .ready-made_kits_catalog{
        width: 100%;
        padding: 20px;
        grid-template-rows: 300px 70px;
        gap: 20px;
        border-radius: 8px;
    }
    .ready-made_kits_catalog .img{
        width: 100% ;
    }
    .ready-made_kits_catalog img{
        max-width: 80%;
        max-height: 180px;
        padding-bottom: 10px;
    }
    /* .ready-made_kits_content{
        max-width: 300px;
        max-height: 70px;
        font-weight: 300;
        font-size: 14px;
    } */
    .ready-made_kits_content h5{
        font-size: 18px;
        font-weight: 300;   
    }
    .ready-made_kits_price p{
        font-size: 24px;
        font-weight: 300;   
        padding-left: 10px;
    }
    .ready-made_kits_price .bascet{
        width: 110px;
        height: 45px;
    }
}
@media (max-width:600px) {
    .ready-made_kits h3{
        font-size: 26px;
    }
    .ready-made_kits_catalog{
        width: 100%;
        grid-template-rows: 330px 70px;
        gap: 20px;
    }
    .ready-made_kits_content{
        width: 100%;
        overflow-y: scroll;
    }
    .ready-made_kits_price{
        padding-top: 10px;
        display: flex;
        justify-content: space-evenly;
    }
}
@media (max-width:549px) {
    .ready-made_kits h3{
        font-size: 26px;
    }
    .ready-made_kits_catalog{
        grid-template-rows: 230px 100px;
        gap: 20px;
        background-color: white;
        cursor: pointer;
        border-radius: 8px;
    }
    .ready-made_kits_content{
        width: 100%;
        max-height: 70px;
        overflow-y: scroll;
        scrollbar-width: none;
    }
    .ready-made_kits_content h5{
        font-size: 15px;
    }
}
