.form{
    width: 100%;
    padding: 60px 7%;
}
.form_title{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
}
.form form{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.form form h4{
    padding: 20px 0 10px;
    font-size: 24px;
    font-weight: 300;
    color: #112038;
}

.form button{
    height: 40px;
    width: 15%;
    border:2px solid rgba(199, 199, 199, 0.296);
    background-color: #e9eaec3b;
    border-radius: 8px;
    background-color: rgb(10, 107, 182);
    color: white;
    cursor: pointer;
    transition: all .5s ease;
}
.form button:disabled{
    background-color: rgb(127, 147, 163);
}
.form button:hover{
    border: 1px solid #3984be;
    box-shadow: 0 0 9px rgba(57,132,190,0.5);
}
.form label{
    width: 40%;
}
.form input{
    width: 100%;
    height: 40px;
    border: none;
    list-style: none;
    background-color: rgba(199, 199, 199, 0);
    padding-left: 10px;
    color: rgb(147, 147, 147);
    font-size: 16px;
    outline: none;
    border:2px solid rgba(199, 199, 199, 0.296);
    border-radius: 8px;
    cursor: pointer;
}
.form input:hover{
    border: 1px solid rgb(10, 107, 182);
    box-shadow: 0 0 9px rgba(36, 93, 136, 0.5);
}
.errorForm{
    position: absolute;
    font-weight: 300;
    font-size: 14px;
    color: rgba(213, 63, 63, 0.712);
}


/*  */



/* Стили для модального окна */
.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Затемнение заднего фона */
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-weight: 300;
}
.modal-content svg{
      fill: green;

  }
  

  

  /*  */
@media(max-width:1250px){
    .form{
        width: 100%;
        padding: 60px 5%;
    }
}
@media (max-width:1100px){
    .form{
        width: 100%;
        padding: 40px 5%;
    }
}
@media (max-width:600px) {
    .form_title{
        font-size: 26px;
    }
    .form form h4{
        font-size: 18px;
    }
    .form form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .form label{
        padding-bottom: 10px;
        width: 100%;
    }
    .form button{
        margin-top: 30px;
        width: 100%;
    }
}