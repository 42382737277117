.products_main{
    padding: 20px 7%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}
.no_products{
    font-size: 24px;
    font-weight: 300;
    color: #11203891;
}
.caatlog_title{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
    width: auto;
    padding-bottom: 10px;
}

.products_filter{
    width: 300px;
}
.products_title_main{
    width: 72%;
}
.products_all{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    width: 120px;
    height: 40px;
    margin: 0 5px;
    border: none;
    cursor: pointer;
    background-color: rgb(10, 107, 182);
    border-radius: 8px;
    color: white;
  }
  .pagination button:disabled{
    opacity: .4;
  }
  .go_home_main{
    width: 100%;
    padding: 0 7%;
  }
.go_home{

    width: 90px;
    font-size: 18px;
    font-weight: 300;
    opacity: .6;
    color: #112038;
  }
  .go_home:hover{
    opacity: 1;
  }
  @media (max-width:1250px) {
    .go_home_main{
        padding: 0 5%;
    }
    .products_main{
        padding: 20px 5%;
    }
  }
  @media (max-width:1100px) {
    .products_filter{
        width: 50%;
    }
    .products_title_main{
        width: 50%;
    }
  }
  @media (max-width:750px) {
    .products_main{
        display: flex;
        flex-direction: column;
    }
    .products_filter{
        width: 100%;
    }
    .products_title_main{
        width: 100%;
    }
  }