.video{
    padding: 60px 7% 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video_plus{
    width: 100%;
    height: 400px;
    overflow: hidden;
}
.video_content{
    margin-top: -150px;
    width: 100%;
    background-color: rgb(52, 52, 52);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(147, 147, 147);
    font-size: 48px;
}
.plus{
    width: 100%;
    padding: 20px 7% 0px 7%;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #f4f6f9;
}
.plus h4{
    font-size: 24px;
    font-weight: 300;
    color: #112038;
}
.plus_box{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.plus_box .box{
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: column;
    color: #314563;
}
.box h5{
    font-weight: 300;
    font-size: 18px;
    line-height: 120%;
    padding-bottom: 10px;
}
.box p{
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
}
.plus_box .box_icons{
    height: 60px;
    padding-bottom: 10px;
}
.map{
    width: 100%; 
    height: 400px; 
    padding: 0px 7%;
}
.map_box{
    width: 100%;
    padding: 60px 0px;
}

.map_content{
    width: 100%;
    padding: 0px 7%;
    display: flex;
    justify-content: space-between;
}
.map_content h3{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
    padding-bottom: 10px;
}
.map_why{
    width: 70%;
}
.map_content p{
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #161616;
}
.map_contact{
    width: 23%;
}
.map_title{
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    color: #161616;
    padding: 20px 7%;
}
.number_absolut_main{
    display: none;
}
.inst_absolut_main{
    display: none;
}
.inst_absolut_main img{
     display: none;
}
@media (max-width:1450px) {
    .map_why{
        width: 65%;
    }
    .map_contact{
        width: 30%;
        text-align: end;
    }
}
@media (max-width:1260px) {
    .video{
        width: 100%;
        height: 500px;
        padding: 60px 5% 0;
    }
    .video_content{
        width: 100%;
    }
    .plus{
        width: 100%;
        padding: 60px 5% 0;
    }
    .plus h4{
        font-size: 40px;
        font-weight: 300;
    }
    .plus_box{
        padding-top: 40px;
        width: 100%;
    }
    .plus_box .box{
        width: 200px;
        height: 150px;
    }
    .box h5{
        font-weight: 350;
        font-size: 18px;
        line-height: 120%;
        padding-bottom: 10px;
    }
    .box p{
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
    }
    .plus_box .box_icons{
        height: 80px;
        padding-bottom: 10px;
    }
    .map{
        width: 100%; 
        height: 500px; 
        padding: 0px 5%;
        padding-bottom: 40px;
    }
    .map_box{
        width: 100%;
        padding: 60px 0px;
    }
    .map_content{
        width: 100%;
        padding: 0px 5%;
    }
    .map_content h3{
        font-size: 40px;
        font-weight: 300;
        padding-bottom: 10px;
    }
    .map_content p{
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
    }
    .map_title{
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        padding: 20px 5%;
    }
}
@media (max-width:1100px) {
    .video{
        width: 100%;
        height: 300px;
        padding: 40px 5% 0;
    }
    .video_plus{
        margin: -150px;
        height: 100%;
    }
    .video_content{
        width: 100%;
    }
    .plus{
        width: 100%;
        padding: 40px 5%;
    }
    .plus h4{
        font-size: 40px;
        font-weight: 300;
    }
    .plus_box{
        width: 100%;
        padding: 0;
    }
    .plus_box .box{
        width: 180px;
        height: 100px;
    }
    .box h5{
        font-weight: 350;
        font-size: 18px;
        line-height: 120%;
        padding-bottom: 10px;
    }
    .box p{
        font-weight: 300;
        font-size: 14px;
        line-height: 150%;
    }
    .plus_box .box_icons{
        height: 80px;
        padding-bottom: 10px;
    }
    .map{
        width: 100%; 
        height: 500px; 
        padding: 0px 5%;
    }
    .map_box{
        width: 100%;
        padding: 20px 0px;
    }
    .map_content{
        width: 100%;
        padding: 0px 5%;
    }
    .map_content h3{
        font-size: 40px;
        font-weight: 300;
        padding-bottom: 10px;
    }
    .map_content p{
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
    }
    .map_title{
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        padding: 20px 5%;
    }
}
@media (max-width:1000px){
    .plus{
        padding: 40px 5% 0;
    }
    .plus_box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
    .plus_box .box{
        width: 100%;
        height: 150px;
    }
    .map_content{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .map_why{
        width: 90%;
        padding-bottom: 40px;
    }
    .map_contact{
        width: 90%;
        text-align: start;
    }
    .map{
        width: 100%;
        height:400px;
    }
}
@media (max-width:750px){
    .plus_box{
        display: grid;
        grid-template-columns: repeat(4, 200px);
        overflow-x: scroll;
        overflow-y: hidden;
        gap: 20px;
        scrollbar-width: none;
    }
    .video_plus{
        margin: 0px;
        height: 100%;
    }
    .video_content{
        margin: 0;
    }
    .number_absolut_main{
        display: flex;
        justify-content: center;
        align-items: center;        
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 10px;
        /* animation: asd 5s infinite; */
        box-shadow: 0px 0px 1px 0px #068b18;
        background-color: rgba(47, 182, 10, 0.571);
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999999;
    }
    .number_absolut{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: rgba(20, 181, 33, 0.277);
        animation: mymove 5s infinite;

    }
    .number_absolut_img{
        fill: rgb(219, 218, 218);    
    }
    @keyframes asd {
        0%{
            transform: rotate(0deg);
          }
          
          50%{
            transform: rotate(110deg);
          }
          
          100%{
            transform: rotate(0deg);
          }
      }
    @keyframes mymove {
        0%{
            transform: scale(1);
        }
        
        50%{
        transform: scale(1.3);
        }
        
        100%{
        transform: scale(1);
        }
    }
    .inst_absolut_main{
    display: flex;
    justify-content: center;
    align-items: center;        
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 9999999;
    }
    .inst_absolut_main img{
        display: block;
    }
    .inst_absolut_img{
    width: 80px;
    height: 80px;
    }
}
@media (max-width:600px) {
    .plus h4{
        font-size: 26px;
    }
    .plus h5{
        font-size:18px;
    }
    .plus p{
        font-size: 14px;
    }
    .map_content h3{
        font-size: 26px;
    }
    .map_content p{
        font-size: 16px;
    }
    .map_contact h3{
        font-size: 26px;
        font-weight: 400;
    }
    .map_title{
        font-size: 14px;
        font-weight: 400;
    }
}
@media (max-width:550px) {
    .video{
        height: auto;
    }
}