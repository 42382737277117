.footer_catalog{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 550px;
    gap: 20px;
}
.footer_catalog_box{
    width: 200px;
}
.link_footer_catalog_name{
    color: #112038;
    text-decoration: none;
    list-style-type: none;
    font-size: 18px;
    cursor: pointer;
    font-weight: 400; 
    padding-top: 10px;   
}
.link_footer_catalog_name:hover{
    text-decoration-line: underline;
    transition: all 5s ease;
}
.footer_catalog_content{
    display: flex;
    flex-direction: column;
}
.link_footer_catalog_content{
    color: #112038;
    text-decoration: none;
    list-style-type: none;
    font-size: 16px;
    cursor: pointer;
    font-weight: 300;  
}
.link_footer_catalog_content:hover{
    text-decoration-line: underline;
    transition: all 5s ease;
}
@media (max-width:1000px) {
    .footer_catalog{
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 20px;
    }
}
@media (max-width:700px) {
    .footer_catalog{
        height: 900px;
    }
}
@media (max-width:450px) {
    .footer_catalog_box{
        width: 170px;
    }
    .footer_catalog{
        row-gap: 20px;
        column-gap: 10px;
    }
}