.footer{
    width: 100%;
    height: 600px;
    padding: 60px 7%;
    background: linear-gradient(0deg,rgb(10, 107, 182) 0%, #f4f6f9 100%); 
    color: #112038;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.footer_left{
    width: 20%;
}
.footer_left img{
    width: 100%;
    margin-bottom: 20px;

}
.footer_left_phone{
    font-size: 24px;
    color: #112038;
    text-decoration: none;
    list-style-type: none;
    font-weight: 300;
}
.footer_left_inst{
    font-weight: 300;
    margin-top: 20px;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
    color: #112038;
    text-decoration: none;
    list-style-type: none;
}
.footer_center{
    width: 70%;
}
@media (max-width:1250px) {
    .footer{
        padding: 0 5%;
    }
}
@media (max-width:1000px) {
    .footer{
        display: flex;
        flex-direction: column;
    }
    .footer_left{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
    }
    .footer_left img{
        width: 20%;
        margin: 0;
    }
    .footer_left_phone{
        margin-top: 20px;
    }
    .footer_center{
        width: 100%;
    }
}
@media (max-width:700px) {
    .footer{
        height: 1000px;
        justify-content: start;
    }
    .footer_left{
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap:wrap;
        column-gap: 20px;
        row-gap: 0px;
        padding-bottom: 20px;
    }
    .footer_left img{
       min-width: 113px;
    }
    .footer_center{
        width: 100%;
    }
    .footer_left svg{
        width: 20px;
    }
    .footer_left_phone{
        margin-top: 10px;
        font-size: 18px;
        color: #112038;
        text-decoration: none;
        list-style-type: none;
        font-weight: 300;
    }
    .footer_left_inst{
        font-weight: 300;
        margin-top: 10px;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90px;
        color: #112038;
        text-decoration: none;
        list-style-type: none;
    }
}