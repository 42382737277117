@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.loader-container{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
body{
    font-family: "Oswald", sans-serif;
    background-color: #f4f6f9;
    transition: all .5s ease;
}
.header{
    background-color: #f4f6f9;
    z-index: 999;
}
.main_header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7%;
    height: auto;
    transition: all .5s ease;
    color: #112038;
    z-index: 999;
    background: linear-gradient(180deg,rgb(10, 107, 182) 0%, #f4f6f9 100%); 
    height: 150px;
}

.main_header_left{
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo_home{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.main_header_center img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.main_header_numb{
    display: flex;
    justify-content: space-between;
    width: 205px;
    align-items: center;
    font-size: 24px;
    font-weight: 300;
    text-decoration: none;
    color: #112038;
}
.main_header_center{
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_header_right{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
}
.main_header_right_a{
    color: #112038;
    text-decoration: none;
    justify-content: space-between;
    font-size: 20px;
    cursor: pointer;
}

.inst{    
    text-decoration: none;
    color: #112038;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: 24px;
    width: 125px;
}

.main_header_right_a:hover{
    text-decoration:underline;
    transition: all 5s ease;
}

.fixed_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0px 7%;
}
.header_catalog{
    width: 200px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(10, 107, 182);
    color: white;
    cursor: pointer;
    transition: all .5s ease;
    fill: white;
}

.header_catalog:hover{
    border: 1px solid #3984be;
    box-shadow: 0 0 9px rgba(57,132,190,0.5);
}
.main_search{
    width: 60%;
}
.search{
    display: flex;
    align-items: center;
    border:2px solid rgba(199, 199, 199, 0.296);
    background-color: #e9eaec3b;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding-left: 15px;
}
.search svg{
    fill: rgb(147, 147, 147);
}
.search input{
    border: none;
    list-style: none;
    background-color: rgba(199, 199, 199, 0);
    width: 90%;
    padding-left: 10px;
    color: rgb(147, 147, 147);
    font-size: 16px;
    outline: none;
}
.search:hover{
    border: 1px solid rgb(10, 107, 182);
    box-shadow: 0 0 9px rgba(36, 93, 136, 0.5);
}
.header_cart{
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border:2px solid rgba(199, 199, 199, 0.296);
    background-color: #e9eaec3b;
    border-radius: 8px;
    background-color: rgb(10, 107, 182);
    color: white;
    cursor: pointer;
    transition: all .5s ease;
    fill: white;
    text-decoration: none;
}
.header_cart:hover{
    border: 1px solid #3984be;
    box-shadow: 0 0 9px rgba(57,132,190,0.5);
}
header{
    position:-webkit-sticky;
    position: sticky;
    top: 0;
}
.basket_num{
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3984be;
    top: 10px;
    margin-left: 120px;
}
.showIsSearch{
    margin-top: 10px;
    width: 52%;
    max-height: 500px;
    height: auto;
    background-color: white;
    list-style: none;
    text-decoration: none;
    overflow: auto;
    position: absolute;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #112038;
}
.showIsSearch_item{
    padding: 10px;
    color: #112038;
    text-decoration: none;
    list-style: none;
    border-bottom: 1px solid rgba(146, 146, 146, 0.554);
}
.showIsSearch_item:hover{
    background: rgb(180, 180, 180);
    cursor: pointer;
    transition: cubic-bezier(1, 0, 0, 1) .3s all;
}
.info_block{
    position: absolute;
    width: 250px;
    height: auto;
    border-radius: 0 8px 8px 8px;
    background-color: #ffffffc6;
    padding: 20px 20px;
    font-size: 16px;
    color:#112038;
    font-weight: 300;
    margin-left: 40px;
    margin-top: 20px;
}
.info_block {
    opacity: 0;
  }
  
  .info_block.visible {
    transition: opacity 0.5s ease;
    opacity: 1;
    z-index: 99999;
  }
.info_block_delivery {
    opacity: 0;
  }
  
  .info_block_delivery.visible {
    transition: opacity 0.5s ease;
    opacity: 1;
    z-index: 99999;
  }

  .info_block_delivery{
    position: absolute;
    width: 250px;
    height: auto;
    border-radius: 8px 0px 8px 8px;
    background-color: #ffffffc6;
    padding: 20px 20px;
    font-size: 16px;
    color:#112038;
    font-weight: 300;
    margin-left: -180px;
    margin-top: 20px;
}

.info_block_contact {
    opacity: 0;
  }
  
  .info_block_contact.visible {
    transition: opacity 0.5s ease;
    opacity: 1;
    z-index: 99999;
  }

  .info_block_contact{
    position: absolute;
    width: 250px;
    height: auto;
    border-radius: 8px 0px 8px 8px;
    background-color: #ffffffc6;
    padding: 20px 20px;
    font-size: 16px;
    color:#112038;
    font-weight: 300;
    margin-left: -220px;    
    margin-top: 20px;
}
@media(max-width:1250px){
    .main_header{
        padding: 0 5%;
        font-size: 14px;
        height: 85px;
    }
    .logo_home{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    .main_header_numb{
        width: 150px;
        font-size: 18px;
    }
    .inst{    
        font-weight: 300;
        font-size: 18px;
        width: 100px;
    }
    .main_header_right_a{
        font-size: 16px;
    }
    .fixed_header{
        padding: 0 5%;
    }
    .info_block{
        width: 150px;
        padding: 20px;
        font-size: 14px;
    }
    .info_block_delivery{
        width: 150px;
        padding: 20px;
        font-size: 14px;
        margin-left: -100px;

    }
    .info_block_contact{
        width: 150px;
        padding: 20px;
        font-size: 14px;
        margin-left: -120px;
    }
}

@media(max-width:1000px){
    .main_header{
        padding: 0 5%;
    }
    .main_header{
        display: grid;
        place-items: center;
        justify-content: center;
        grid-template-rows: 130px 32px 44px;
        gap: 10px;
        height: auto;
    }
    .main_header_left{
        grid-row-start: 2;
        width: 100%;
        display: grid;
        place-items: center;
        justify-content: center;
        grid-template-columns: repeat(2, 165px);
        gap: 10px;
    }
    .main_header_center{
        width: 100%;
        grid-row-start: 1;
    }
    .main_header_right{
        grid-row-start: 3;

    }
    .main_header_numb{
        width: 100%;
        justify-content: start;
        
    }
    .main_header_left #numb{
        padding-right: 15px;
        width: 35px;
    }
    .main_header_left a{
        width: 100%;
        justify-content: end;
    }
    .main_header_left #inst{
        width: 35px;
        padding-right: 15px;
    }
    .main_header_right{
        padding: 10px;
        width: 300px;
        justify-content: space-around;
    }

    .fixed_header{
        width: 100%;
        height: 60px;
        padding: 8px 5%;
    }
    .header_catalog{
        width: 150px;
        height: 40px;
        border-radius: 8px;
        font-size: 12px;
    }
    .header_catalog svg{
        width: 12px;
    }
    .main_search{
        width: 55%;
    }
    .search{
        height: 40px;
        border-radius: 8px;
        padding-left: 12px;
        font-size: 12px;
    }
    .search svg{
        fill: rgb(147, 147, 147);
        width: 12px;
    }
    .search input{
        width: 90%;
        padding-left: 10px;
        font-size: 12px;
    }
    .header_cart{
        width: 100px;
        height: 40px;
    }
    .header_cart svg{
        width: 12px;
    }
    .basket_num{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3984be;
        top: 0px;
        margin-left: 100px;
    }
}
@media(max-width:750px){
    .main_header{
        padding: 0 5%;
    }
    .fixed_header{
        width: 100%;
        height: 60px;
    }
    .header_catalog{
        width: 40px;
        height: 40px;
        border-radius: 8px;
        font-size: 12px;
    }
    .header_catalog p{
        display: none;
    }
    .header_catalog svg{
        width: 20px;
    }
    .main_search{
        width: 70%;
    }
    .search{
        height: 40px;
        border-radius: 8px;
        padding-left: 12px;
        font-size: 12px;
    }
    .search svg{
        fill: rgb(147, 147, 147);
        width: 20px;
    }
    .search input{
        width: 90%;
        padding-left: 10px;
        font-size: 12px;
    }
    .header_cart{
        width: 40px;
        height: 40px;
    }
    .header_cart svg{
        width: 20px;
    }
    .header_cart p{
        display: none;
    }
    .basket_num{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3984be;
        top: 0px;
        margin-left: 50px;
    }
}
@media (max-width:400px) {
    .main_header_right{
        width: 100%;
        justify-content: space-between;
        padding: 10px 0;
    }
}