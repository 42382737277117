.product{
    width: 100%;
    padding: 40px 0;
}
.productdisplay_title{
    padding: 0px 7% 60px 7%;
    font-size: 32px;
    font-weight: 300;
    color: #112038;
}
.productdisplay{
    padding: 0 7%;    
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.productdisplay__left{
    width: 32%;
    box-shadow: 0 0 3px rgba(122, 130, 136, 0.204);
    border-radius: 8px;
    background-color: white;
}
.productdisplay__left .swiper-backface-hidden .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
}
.productdisplay__img{
    width: 100%;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.productdisplay__img img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.productdisplay__img__list{
    width: 100%;
    height: 80px;
}
.productdisplay__img__list img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.productdisplay__center{
    padding: 20px;
    box-shadow: 0 0 3px rgba(122, 130, 136, 0.204);
    font-stretch:expanded;
    color: #112038;
    font-weight: 300;
    width: 25%;
    height: 350px;
    gap: 20px;
    border-radius: 8px;
}
.productdisplay__buy{
    width: 200px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(10, 107, 182);
    color: white;
    cursor: pointer;
    transition: all .5s ease;
    fill: white;
    gap: 10px;
}
.productdisplay_price{
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 300;
}
.tableRow{
    display: flex;
    width: 100%;
    gap: 10px;
}
.characteristics_main{
    margin-top: 40px;
}
.characteristics_title{
    font-weight: 300;
    font-size: 32px;
}
.tableRow_lef{
    opacity: .7;
}
.tableRow_cent{
    opacity: .7;
}
.productdisplay__rigth{
    width: 30%;
    height: 150px;
    background-color: #dedede46;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    color: #112038;
    font-weight: 300;
    gap: 5px;
}
.productdisplay__rigth_main{
    font-size: 24px;
}
.productdisplay__rigth_content{
    font-size: 18px;
    opacity: .8;
}
.addinational_information_main{
    width: 100%;
    padding: 40px 7%;
}
.addinational_information{
    width: 100%;
    height: auto;
    padding: 20px;
    box-shadow: 0 0 3px rgba(122, 130, 136, 0.204);
    border-radius: 8px;
}
.addinational_tableRow{
    display: flex;
    gap: 15px;
    padding-bottom: 5px;
}
.addinational_tableRow_lef{
    width: 100px;
    font-size: 18px;
    opacity: .8;
}
.addinational_information{
    color: #112038;
    font-weight: 300;
}
.addinational_title{
    color: #112038;
    font-weight: 300;
    font-size: 32px;
    padding-bottom: 20px;
}
.addinational_tableRow_righ{
    font-size: 18px;
}
.productdisplay__img{
    margin-bottom: 10px;
}
.showImg{
    position: absolute;
    height: 100%;
    background-color: white;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.showImg__list .swiper-backface-hidden .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
}
.addinational_table_main{
    padding-top: 20px;
    width: 100%;
    gap: 10px;
}
.addinational_tableRow_top{
    font-size: 20px;
}
.addinational_tableRow_bottom{
    font-size: 18px;
}

.loader{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.err{
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 300;
}
/*  */

.showImg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(254, 254, 254);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .showImg__list {
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .showImg__list img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .close {
    position: absolute;
    top: 0;
    right: 10px;
    color: rgb(60, 60, 60);
    font-size: 40px;
    cursor: pointer;
  }
  
  .showImg__list .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /*  */
@media (max-width:1250px) {
    .productdisplay_title{
        padding: 0px 5% 20px 5%;
    }
    .productdisplay{
        padding: 0 5%;    
    }.addinational_information_main{
        padding: 20px 5%;
    }
    .productdisplay__left{
        width: 40%;
    }
    .productdisplay__center{
        width: 40%;
    }
}
@media (max-width:1000px) {
    .productdisplay_title{
        font-size: 26px;
    }
    .productdisplay__left{
        width: 60%;
    }
    .productdisplay__rigth{
        display: none;
    }
    .showImg {
        width: 100%;
        height: 100%;
      }
}
@media (max-width:750px) {
    .productdisplay_title{
        font-size: 20px;
    }
    .productdisplay{
        display: flex;
        flex-direction: column;
    }
    .productdisplay__left{
        width: 100%;
    }
    .productdisplay__center{
        width: 100%;
    }
    .productdisplay__rigth{
        display: none;
    }
    .addinational_tableRow_cent{
        display: none;
    }
    .addinational_tableRow_lef{
        width: 200px;
        font-size: 16px;
    }
    .addinational_tableRow{
        border-bottom: 1px solid rgba(155, 153, 153, 0.586);
    }
    .addinational_tableRow_righ{
        font-size: 16px;
        width: 130px;
    }
    .characteristics_main{
        display: none;
    }
    .productdisplay__center{
        height: 200px;
    }
    .productdisplay__img{
        height: 350px;
    }
    .productdisplay_price{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .product{
        padding: 20px 0;
    }
    .addinational_table_main{
        padding-top: 20px;
        width: 100%;
        gap: 10px;
    }
    .addinational_tableRow_top{
        font-size: 18px;
    }
    .addinational_tableRow_bottom{
        font-size: 16px;
    }
}
