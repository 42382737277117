.brands{
    width: 100%;
    padding: 60px 7% 60px 7%;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: white;
}
.brands .swiper-wrapper{
    display: flex;
    align-items: center;
}
.brands h3{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
}
.brands h4{
    padding-top: 20px;
    font-size: 32px;
    font-weight: 300;
    color: #112038;
}
.brand_all{
    width: 100%;
    overflow: hidden;
}
.brand_box{
    position:inherit;
    padding-top: 20px;
    display: flex;
    align-items: center;    
    gap: 20px;
    columns: 1;
}
.brand_main{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    border-radius: 8px;
    background-color: white;
}
.brand_main img{
    width: 80%;
    object-fit: contain;
    display: flex;
    justify-content: center;
}

.brand_main:hover{
    border: 1px solid rgb(10, 107, 182);
    box-shadow: 0 0 9px rgba(36, 93, 136, 0.5);
}

@media (max-width:1260px) {
    .brands{
        width: 100%;
        padding: 60px 5%;
    }
    .brands h4{
        padding-top: 20px;
    }
    .brand_box{
        padding: 0;
    }
}
@media (max-width:1100px) {
    .brands{
        width: 100%;
        padding: 20px 5%;
    }
    .brands h3{
        font-size: 40px;
        font-weight: 300;
    }
    .brand_all{
        width: 100%;
    }
    .brand_box{
        gap: 20px;
        columns: 1;
    }
    .brand_main{
        width: 100%;
        padding: 20px;
        /* grid-template-rows: 280px 50px; */
        gap: 20px;
        border-radius: 8px;
    }
    /* .brand_main img{
        width: 100% ;
    } */
    /* .catalog_main img{
        width: 80%;
        padding-bottom: 10px;
    } */
}
@media (max-width:1000px) {
    
}
@media (max-width:600px) {
    .brands h3{
        font-size: 26px;
    }
    .brands h4{
        font-size: 18px;
    }
    .brand_main{
        width:100%;
        height: auto;
    }
}
@media (max-width:483px) {
    .brand_main{
        width: 100%;
    }
}
