.form_bot{
    width: 100%;
    padding: 60px 0;
}
.form_bot_title{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
}
.form_bot form{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.form_bot form h4{
    padding: 20px 0 10px;
    font-size: 24px;
    font-weight: 300;
    color: #112038;
}

.form_bot button{
    height: 40px;
    width: 15%;
    border:2px solid rgba(199, 199, 199, 0.296);
    background-color: #e9eaec3b;
    border-radius: 8px;
    background-color: rgb(10, 107, 182);
    color: white;
    cursor: pointer;
    transition: all .5s ease;
}
.form_bot button:disabled{
    background-color: rgb(127, 147, 163);
}
.form button:hover{
    border: 1px solid #3984be;
    box-shadow: 0 0 9px rgba(57,132,190,0.5);
}
.form_bot label{
    width: 40%;
}
.form_bot input{
    width: 100%;
    height: 40px;
    border: none;
    list-style: none;
    background-color: rgba(199, 199, 199, 0);
    padding-left: 10px;
    color: rgb(147, 147, 147);
    font-size: 16px;
    outline: none;
    border:2px solid rgba(199, 199, 199, 0.296);
    border-radius: 8px;
    cursor: pointer;
}
.form_bot input:hover{
    border: 1px solid rgb(10, 107, 182);
    box-shadow: 0 0 9px rgba(36, 93, 136, 0.5);
}
.errorForm{
    position: absolute;
    font-weight: 300;
    font-size: 14px;
    color: rgba(213, 63, 63, 0.712);
}

@media(max-width:1250px){
    .form_bot{
        width: 100%;    }
}
@media (max-width:1100px){
    .form_bot{
        width: 100%;
        padding: 40px 0;
    }
}
@media (max-width:600px) {
    .form_bot_title{
        font-size: 26px;
    }
    .form_bot form h4{
        font-size: 18px;
    }
    .form_bot form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .form_bot label{
        padding-bottom: 10px;
        width: 100%;
    }
    .form_bot button{
        margin-top: 30px;
        width: 100%;
    }
}