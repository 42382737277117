.basket{
    padding: 20px 7%;
}
.basket h3{
    font-size: 40px;
    font-weight: 300;
    color: #112038;
    padding-bottom: 20px;
}
.basket_top{
    width: 100%;
    display: grid;
    grid-template-columns: 70% 25%;
    gap: 5%;
}
.basket_top_catalog_main{
    width: 100%;
    height: auto;
    border: .5px solid rgba(169, 169, 169, 0.319);
    border-radius: 8px;
    overflow: hidden;
}
.basket_top_catalog{
    width: 100%;
    padding: 20px 40px;
    display: grid;
    grid-template-columns: 100px 200px 100px 50px 80px;
    justify-content: space-between;
    align-items: center;
    border: .5px solid rgba(169, 169, 169, 0.319);
}
.basket_top_catalog_img{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.basket_top_catalog_img img{
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

}
.basket_top_catalog_title{
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    color: #112038;
}
.basket_top_total{
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: .5px solid rgba(169, 169, 169, 0.319);
    width: 100%;
    height: 160px;
    font-size: 24px;
    line-height: 200%;
    font-weight: 300;
    color: #112038;
}
.basket_top_price_line{
    width: 100%;
    border-bottom: .5px solid rgba(169, 169, 169, 0.729);
}
.del_basket{
    width: 80px;
    cursor: pointer;
}

@media (max-width:1250px) {
    .basket{
        padding: 20px 5%;
    }
}
@media (max-width:1000px) {
    .basket{
        padding: 20px 5%;
    }
    .basket_top{
        display: flex;
        flex-direction: column;
    }
    .basket h3{
        font-size: 32px;
        font-weight: 300;
        color: #112038;
        padding-bottom: 20px;
    }
}
@media (max-width:750px) {
    .basket_top_catalog{
        width: 100%;
        padding: 10px;
        grid-template-columns: 75px 150px 100px 20px 40px;
    }
    .basket_top_catalog_title{
        font-size: 14px;
    }
    .del_basket{
        width: 100%;
        cursor: pointer;
    }
    .basket_top_catalog_title{
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        color: #112038;
    }
    .basket_top_total{
        padding: 10px;
        width: 100%;
        height: 100px;
        font-size: 16px;
        line-height: 200%;
        font-weight: 300;
        color: #112038;
    }
}
@media (max-width:500px){
    .basket_top_catalog{
        width: 100%;
        padding: 10px;
        grid-template-columns: 70px 100px 70px 10px 20px;
    }
}