.showCatalog_main{
    width: 100%;
    height: 600px;
    padding: 0px 7%;
    background-color:white;
    position: absolute;
    left: 0;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    transition: .5s all;
}
.showCatalog_main_box{
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
}

.showCatalog_name{
    width: 180px;
    height: auto;
    background-color: #f4f6f9;
    border-radius: 8px;
    display: flex;
    padding: 10px;
    justify-content: start;
    align-items: center;
    font-weight: 300;
    transition: .5s;
    margin: 10px 0;
    cursor: pointer;
}
.showCatalog_box{
    width: 180px;
    height: 100%;
}
.link_main{
    text-decoration: none;
    color: #112038;
    width: 100%;
}
.showCatalog_name:hover{
    background-color: #e5e9f1;
    transition: .5s;
}
.showCatalog_content{
    position: absolute;
    top: 0;
    left: 400px;
}
.showCatalog_content ul{
    width: 600px;
    height: 600px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.showCatalog_content .link{
    text-decoration: none;
    list-style-type: none;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    color: #112038;
}
.showCatalog_content .link:hover{
    text-decoration-line: underline;
    transition: all 5s ease;
}


@media (max-width:1250px) {
    .showCatalog{
        padding: 0 5%;
    }
    .showCatalog_content{
        left: 300px;
    }
    .showCatalog_content ul{
        width: 250px;
    }
}
@media(max-width:750px){
    .showCatalog{
        height: auto;
        font-size: 14px;
    }
    .showCatalog_name{
        width: 100%;
        margin: 0;
    }
    .showCatalog_box{
        width: 100%;
    }
    .showCatalog_name{
        width: 100%;
        border: 1px solid #112038;
    }
    .showCatalog_content{
        position: relative;
        left: 0;
        height: auto;
    }
    .showCatalog_content ul{
        height: auto;
        width: 100%;
    }
    .showCatalog_content .link{
        width: 100%;
        border-bottom: 1px solid #112038;
        font-size: 14px;
    }
    .link_main{
        width: auto;
    }
}
